<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    max-width="550px"
    style="z-index: 6"
    width="300"
    height="330"
  >
    <v-form ref="addCashRegisterForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Blagajna</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="pt-0">
              <v-text-field
                autofocus
                dense
                outlined
                label="Naziv"
                v-model="registerName"
                :rules="[rules.req]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-text-field
                autocomplete="off"
                dense
                outlined
                label="Oznaka blagajne"
                hint="Samo brojevi"
                v-mask="'###########'"
                v-model="registerId"
                :rules="[rules.req, rules.reservedIds]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-select
                dense
                outlined
                label="Vrsta blagajne"
                v-model="registerType"
                :items="registerTypes"
                :rules="[rules.req]"
                item-text="name"
                item-value="value"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <money-input
                dense
                outlined
                label="Polog na blagajni"
                v-model="deposit"
                ref="deposit"
                :currency="currency"
              >
              </money-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-select
                dense
                outlined
                label="Vrsta prijave na blagajnu"
                v-model="registerLoginType"
                :items="registerLoginTypes"
                :rules="[rules.req]"
                item-text="name"
                item-value="value"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-select
                dense
                outlined
                label="Nakon izdavanja računa"
                v-model="afterReceiptBehaviour"
                :items="afterReceiptBehaviours"
                :rules="[rules.req]"
                item-text="name"
                item-value="value"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row class="pb-0 pt-0">
            <v-col class="pb-0 pt-0">
              <v-select
                class="pb-3"
                outlined
                dense
                label="Odaberite printer"
                :items="printers"
                item-text="name"
                item-value="id"
                hide-details
                v-model="selectedPrinter"
                :rules="[rules.req]"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton mb-2"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Dodaj blagajnu
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <add-printer ref="addPrinter"></add-printer>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'
import { df, auth } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import { v4 as uuidv4 } from 'uuid'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import MoneyInput from '@/components/MoneyInput'
import AddPrinter from '@/modules/point-of-sale/components/printer-configuration/AddPrinter'

export default {
  name: 'AddCashRegister',
  data: () => ({
    visible: false,
    submitting: false,
    deposit: '',
    error: {
      show: false,
      message: undefined
    },
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    registerName: undefined,
    registerId: undefined,
    registerType: undefined,
    registerTypes: [],
    registerLoginType: undefined,
    registerLoginTypes: [],
    afterReceiptBehaviour: undefined,
    afterReceiptBehaviours: [],
    printers: [],
    selectedPrinter: undefined,
    currency: 'EUR',
    listeners: []
  }),
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: { MoneyInput, AddPrinter },
  computed: {
    reservedIds () {
      return state.registerIds
    }
  },
  mounted () {
    this.rules.reservedIds = (val) =>
      !this.reservedIds.includes(parseInt(val)) ||
      `Blagajna s oznakom ${val} već postoji!`
    EventBus.$on('open-cash-register', () => this.open())
  },
  methods: {
    open () {
      const printers = []

      printers.push({ name: 'POS printer 58mm (T58)', value: 'T58', id: 'T58', library: 'NONE', width: 'T58' })
      printers.push({ name: 'POS printer 80mm (T80)', value: 'T80', id: 'T80', library: 'NONE', width: 'T80' })
      printers.push({ name: 'Klasični printer (A4)', value: 'A4', id: 'A4', library: 'NONE', width: 'A4' })

      const query = df
        .collection(`location_printers/${state.getPointOfSale().id}/printers`)
      const listener = query
        .onSnapshot((doc) => {
          doc.docs.forEach((rec) => {
            printers.push(rec.data())
          })
          this.printers = clone(printers.sort((a, b) => a.name.localeCompare(b.name)))
        })

      this.listeners.push(listener)
      this.deposit = ''
      this.visible = true
      this.submitting = false
      // const dooType = ['doo', 'jdoo'].includes(state.getCurrentCompany().type)
      this.registerTypes = [
        {
          name: this.$t('$vuetify.cashRegister.registerTypes.mp'),
          value: 'MP',
          disabled: !state.getCurrentCompany().certificate
        },
        {
          name: this.$t('$vuetify.cashRegister.registerTypes.vp'),
          value: 'VP'
        },
        {
          name: this.$t('$vuetify.cashRegister.registerTypes.all'),
          value: 'ALL',
          disabled: !state.getCurrentCompany().certificate
        }
      ]

      this.registerLoginTypes = [
        {
          name: this.$t('$vuetify.cashRegister.registerLoginTypes.default'),
          value: 'DEFAULT'
        },
        {
          name: this.$t('$vuetify.cashRegister.registerLoginTypes.selectUser'),
          value: 'SELECT_USER'
        },
        {
          name: this.$t('$vuetify.cashRegister.registerLoginTypes.pincode'),
          value: 'PINCODE'
        }
      ]

      this.afterReceiptBehaviours = [
        {
          name: this.$t('$vuetify.cashRegister.afterReceiptBehaviours.emptyBasket'),
          value: 'EMPTY_BASKET'
        },
        {
          name: this.$t('$vuetify.cashRegister.afterReceiptBehaviours.showCashRegs'),
          value: 'SHOW_CASH_REGS'
        },
        {
          name: this.$t('$vuetify.cashRegister.afterReceiptBehaviours.showAllReceipts'),
          value: 'SHOW_ALL_RECEIPTS'
        }
      ]

      this.registerLoginType = this.registerLoginTypes[0]
      this.afterReceiptBehaviour = this.afterReceiptBehaviours[0]

      this.registerType = this.registerTypes.find(
        (type) => !type.disabled
      ).value
    },
    close () {
      this.$emit('close')
      this.visible = false
      this.$refs.addCashRegisterForm.reset()
      this.$refs.deposit.$el.getElementsByTagName(
        'input'
      )[0].value = ''
      this.deposit = ''
    },
    submit () {
      const that = this
      if (!this.$refs.addCashRegisterForm.validate()) return
      this.submitting = true

      try {
        const reqId = uuidv4()
        const payload = {
          action: {
            operation: 'set',
            entity: 'cash_register',
            params: {
              name: this.registerName,
              number: parseInt(this.registerId),
              company_id: state.getCurrentCompany().id,
              location_id: state.getPointOfSale().id,
              type: this.registerType,
              view_type: 'GROUP_BY_CATEGORIES',
              login_type: this.registerLoginType.value,
              after_receipt_behaviour: this.afterReceiptBehaviour.value
            }
          }
        }

        if (this.selectedPrinter && this.selectedPrinter !== '') {
          payload.action.params.printer = this.selectedPrinter
        }

        const deposit = this.$refs.deposit.currencyToFloat(this.deposit) * 100
        if (deposit && deposit > 0) payload.action.params.deposit = deposit

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'cashregister',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function (doc) {
            that.$emit('success', payload.action.params)
            setTimeout(() => {
              that.$refs.addCashRegisterForm.reset()
              that.$refs.deposit.$el.getElementsByTagName(
                'input'
              )[0].value = ''
              that.visible = false
            }, 100)
          })
          .catch(function (error) {
            // console.error('Error writing document: ', error)
            that.showMsgBox({
              text: error || 'Internal error',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            that.submitting = false
          })
          .finally(() => {})
      } catch (error) {
        that.showMsgBox({
          text: error || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        that.submitting = false
      }
    }
  }
}
</script>
<style scoped>
