import { auth, df } from '@/plugins/firebase'
import state from '@/state'
import AddCashRegister from '@/modules/point-of-sale/components/AddCashRegister'
import EventBus from '@/plugins/event-bus'
export default {
  components: { AddCashRegister },
  inject: ['showMsgBox', 'confirm', 'confirmClose'],
  data: () => ({
    listeners: []
  }),
  mounted () {
    this.listeners.push(df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`).onSnapshot((doc) => {
      if (!doc ||
        !doc.data() ||
        !doc.data().locations ||
        Object.keys(doc.data().locations).length < 1) {
        this.confirm({
          title: 'Dodaj prodajno mjesto',
          message: 'Još nemate niti jedno dodano prodajno mjesto, dodajte barem jedno prije daljnih akcija!',
          options: {
            toolbar: true,
            width: 410,
            confirmText: 'Dodaj prodajno mjesto',
            cancelText: this.$t('$vuetify.close')
          }
        }).then(resp => {
          if (resp) {
            this.confirmClose()
            this.$router.push({
              name: 'company.pointsOfSale',
              params: { companyId: state.getCurrentCompany().id }
            })
            setTimeout(() => {
              EventBus.$emit('add-pSale')
            }, 50)
          }
        })
      }
    }))

    if (state.getPointOfSale() && state.getPointOfSale().id) {
      this.listeners.push(df.doc(`user_cash_registers/${auth.currentUser.uid}.${state.getCurrentCompany().id}.${state.getPointOfSale().id}`).onSnapshot((doc) => {
        // ANDRIC REKAO MAKNI POPUP
        // if (!doc || !doc.exists ||
        //             !doc.data() ||
        //             !doc.data().cash_registers ||
        //             Object.keys(doc.data().cash_registers).length < 1
        // ) {
        //   this.confirm({
        //     title: 'Dodaj blagajnu',
        //     message: 'Još nemate niti jednu dodanu blagajnu, dodajte barem jednu prije daljnih akcija!',
        //     options: {
        //       toolbar: true,
        //       width: 410,
        //       confirmText: 'Dodaj blagajnu',
        //       cancelText: this.$t('$vuetify.close')
        //     }
        //   }).then(resp => {
        //     if (resp) {
        //       this.confirmClose()
        //       this.$router.push({
        //         name: 'cashRegisters',
        //         params: { companyId: state.getCurrentCompany().id, locationId: state.getPointOfSale().id }
        //       })
        //       setTimeout(() => {
        //         EventBus.$emit('open-cash-register')
        //       }, 50)
        //     }
        //   })
        //   // that.showMsgBox({ text: 'Jos nemate niti jednu dodanu blagajnu, dodajte barem jednu prije daljnih akcija!', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        // }
        //     if (doc && doc.exists) {
        //     if (!doc.data() || !doc.data().cash_registers || doc.data().cash_registers.length < 1) {
        //         that.showMsgBox({ text: 'Jos nemate niti jednu dodanu blagajnu, dodajte barem jednu prije daljnih akcija!', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        //     }
        // }
      }))
    }
  },
  beforeDestroy () {
    this.listeners.forEach(listener => listener())
  }
}
